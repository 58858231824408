$font-primary: 'Open Sans',
sans-serif;
$font-secondary: arial,
helvetica,
sans-serif;
$color-primary: #fff;
html {
    -webkit-font-smoothing: antialiased;
}

body {
    /* Margin bottom by footer height */

    font-family: $font-primary;
    font-weight: 400;
    height: 100%;
}

body.landing {
    background: url(../images/background_mini.jpg) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: bottom center;
}

body.b-locator {
    background-color: #ebebeb;
    background: -webkit-linear-gradient(100% 0%, #dedede, #f4f4f4);
    background: -moz-linear-gradient(100% 0%, #dedede, #f4f4f4);
    background: -o-linear-gradient(100% 0%, #dedede, #f4f4f4);
    background: -ms-linear-gradient(100% 0%, #dedede, #f4f4f4);
    background: linear-gradient(100% 0%, #dedede, #f4f4f4);
    min-height: 100%;
}

.dealer-map {
    height: 242px;
}

.navbar {
    margin-bottom: 0px;
}

.navbar-inverse {
    background-color: #fff;
    border-color: #fff;
}

.navbar-static-top {
    padding-left: 15px;
    height: 105px;
}

.navbar-brand {
    float: left;
    height: auto;
    padding: 0px;
    font-size: 18px;
    line-height: 20px;
}

.navbar-static-top .navbar-brand .logo {
    margin-top: 30px;
    height: auto;
    width: 300px;
}

.slogan h1 {
    font-family: $font-secondary;
    font-size: 24px;
    color: #353435;
    float: right;
    line-height: 45px;
    margin-top: 31px;
    padding-right: 30px;
}

.logo-red {
    color: #0a4f9e;
}

.logo-blue {
    color: #e62332;
}

.cont-slogan {
    background-color: #fff;
    height: 50px;
    margin-top: 200px;
}

.cont-slogan h2 {
    font-family: $font-secondary;
    font-size: 20px;
    color: #353435;
    line-height: 50px;
    margin: 0px;
    padding: 0px;
}

.cont-start {
    background-color: #353435;
    height: 60px;
}

.landing-search {
    margin-top: 8px;
}

.glyphicon-search {
    font-size: 24px;
    float: left;
    margin-left: 10px;
    margin-right: 12px;
    margin-top: -2px;
    color: #d8d8d8;
}

.start {
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    font-style: normal;
    line-height: 62px;
    height: 60px;
    padding-right: 20px;
    float: left;
}

.cont-search {
    background-color: #e62332;
    height: 45px;
}

.cont-search p {
    font-size: 15px;
    color: #fff;
    line-height: 45px;
    font-weight: 600;
}

.places {
    font-size: 13px;
    color: #fff;
    padding-left: 10px;
}

.places a {
    font-size: 13px;
    color: #fff;
    padding-left: 5px;
    padding-right: 5px;
}

.places a:hover {
    color: #353435;
    text-decoration: none;
}

.share-text {
    float: left;
    font-size: 15px;
    color: #fff;
    position: absolute;
    top: 13px;
    left: 9px;
    cursor: pointer;
    display: block;
}

.at4-icon.aticon-compact,
.at4-icon.aticon-more,
.at4-icon.aticon-expanded,
.at4-icon.aticon-addthis {
    background: url(none) no-repeat left;
    visibility: hidden;
}

.at-share-tbx-element.addthis_32x32_style .at-share-btn,
.at-share-tbx-element.addthis_32x32_white_style .at-share-btn {
    max-width: 32px;
    max-height: 32px;
    opacity: 0;
}

.form-inline .form-control {
    display: inline-block;
    vertical-align: middle;
    margin-top: 8px;
    height: 44px;
}

.form-control {
    display: block;
    width: 640px;

    font-size: 16px;

    color: #989898;
    background-color: #424242;
    background-image: none;
    border: none;
    border-radius: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;


}

.form-control.city {
    width: 640px;
    margin-right: 10px;
    display: block;
    padding-top: 10px;

}

.btn {
    display: inline-block;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 600;
    padding-top: 8px;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;
    height: 44px;
}

.btn-primary {
    color: #fff;
    background-color: #353435;
    border: none;
    height: 44px;
}

.btn-primary:hover {
    color: #fff;
    background-color: #353435;
    border: none;
    height: 44px;
}

.btn-default {
    font-family: $font-primary;
    font-size: 16px;
    font-weight: 400;
    line-height: 33px;
    color: #989898;
    background-color: #424242;
    border: none;
    height: 44px;
    width: 260px;
    text-align: left;
}

.btn-default:hover,
.btn-default:focus,
.btn-default.focus,
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
    color: #989898;
    background-color: #424242;
    border-color: #adadad;
    -webkit-box-shadow: none;
    width: 260px;
}

.multiselect-selected-text {
    float: left;
}

.fa-angle-down {
    margin-top: -1px;
    float: right;
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);

}

.btn-group.open .fa-angle-down {
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);

}

.fa {
    font: normal normal normal 20px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    transform: translate(0, 0);
}

.form-control:focus {
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
}

#at15s.atm {
    margin-left: -120px;
}

.footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 115px;
    background-color: #2d2d2d;
}

body.b-locator .footer {
    margin-top: 40px;
    position: relative;
    bottom: 0;
    width: 100%;
    height: 115px;
    background-color: #2d2d2d;
}

.footer-left {
    height: 115px;
    background-image: url(../images/footer-tyre.png);
    background-repeat: no-repeat;
;
    background-position: left center;
}

.footer-logo {
    width: 300px;
    margin-top: 35px;
}

.footer-right p {
    padding-top: 47px;
    font-size: 13px;
    color: #989898;
    font-weight: 600;
    letter-spacing: -1px;
    text-align: right;
    padding-right: 11px;
}

.footer-right p img {
    padding: 0px 5px 0px 5px;
}
/* Locator page styles */

.locator-marg {
    margin-top: 25px;
    padding-right: 0px;
}

.f-locator {
    padding-left: 10px;
    padding-right: 0px;
}

body.b-locator .form-inline .form-control.city {
    width: 664px;
}

.full-holder {
    padding-left: 10px;
    padding-right: 10px;
}

.tab-pane {
    padding-top: 10px;
    padding-bottom: 10px;
}

.map-holder {
    background-color: #d5212e;
    margin-left: -10px;
    margin-right: -10px;
    padding-left: 10px;
    padding-right: 10px;
}

.main-details-holder {
    background-color: #2e6fb5;
    margin-left: -10px;
    margin-right: -10px;
    padding-left: 10px;
    padding-right: 10px;
}

.no-pads {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -10px;
}

.no-pads-second {
    padding-right: 0px;
    margin-right: -10px;
}

.location-links a.loc,
.location-links a.loc:visited,
.location-links a.loc:active,
.location-links a.loc:focus {
    display: block;
    height: 60px;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    padding-top: 12px;
    padding-left: 12px;
    background-color: #113f7e;
    border-bottom: 1px solid #2e6fb5;
    text-decoration: none;

    &.active {
        text-decoration: none;
        background-color: #2e6fb5;
    }
}

.location-links a.loc:hover {
    text-decoration: none;
    background-color: #2e6fb5;
}

.number {
    font-size: 24px;
    font-weight: 400;
    display: block;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    border: 2px solid #4188c7;
    text-align: center;
    line-height: 30px;
    float: left;
    margin-right: 10px;
}

.comp-name {
    width: auto;
    display: block;
    float: left;
    line-height: 37px;
    font-size: 16px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;

}

.miles {
    font-size: 16px;
    color: #a4d3f3;
    font-weight: 400;
    line-height: 37px;
}

.distance {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    line-height: 37px;
    background-image: url(../images/link-pointer-off.png);

    background-position: left center;
    padding-left: 17px;
    background-repeat: no-repeat;
    display: block;
    float: right;
    padding-right: 15px;
}


.location-links a:hover .distance {
    background-image: url(../images/link-pointer-on.png);
}

.location-links a.active .distance {
    background-image: url(../images/link-pointer-on.png);
}

.title-holder {
    padding-top: 12px;
}

.details-title {
    color: #fff;
    font-size: 18px;
    font-weight: 600;

    display: block;
    float: left;
    margin-top: -4px;

  white-space: nowrap;
  overflow: hidden;

  padding-right: 30px;

    a {
        color: #fff;

        &:hover {
            color: #b0d9f6;
            text-decoration: none;
        }
    }
}

.tel-num {
    font-size: 18px;
    font-weight: 600;
    color: #fff;

    padding-right: 80px;
    width:310px;
}
.tel-num a {
    color:#fff;
}
a.email-dealer {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    padding-right: 105px;
}
a.email-dealer:hover {
    color: #b0d9f6;
    text-decoration: none;
}
a.website-dealer {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    background-size: 19px 19px;
    padding-bottom: 2px;
}
a.website-dealer:hover{
    color: #b0d9f6;
    text-decoration: none;
}
.dealer-contact {
    .fa-phone {
        float: left;
        margin-right: 5px;
        font-size: 22px;
        line-height: 25px;
    }
    .fa-envelope-o {
        margin-right: 3px;
        font-size: 21px;
        line-height: 22px;
    }
    .fa-desktop {
        margin-right: 3px;
        font-size: 18px;
        line-height: 22px;

    }
}

.small-map-holder {
    clear: both;
    padding-top: 18px;
}

.main-info {
    clear: both;
    padding-top: 24px;
    font-size: 12px;
    color: #a4d3f3;
    line-height: 20px;
    height: auto;
}

.tab-content > .tab-pane .main-info p {
    color: #a4d3f3;
}

.address-title {
    font-size: 14px;
    font-weight: 600;
    color: #FFF;
}

.main-info div {
    height: 100px;
    padding-left: 45px;
    padding-right: 20px;
}

.address {
    background-image: url(../images/detail-pointer.png);

    background-repeat: no-repeat;
    background-position: 20px 2px;
}



.opening {
    background-image: url(../images/detail-clock.png);

    background-repeat: no-repeat;
    background-position: 16px 2px;
}



.directions {
    background-image: url(../images/detail-directions.png);

    background-repeat: no-repeat;
    background-position: 16px 2px;
}




.address,
.opening {
    border-right: 1px solid #2462ab;
}

.hr {
    margin-top: 30px;
    border-bottom: 1px solid #2462ab;
    clear: both;
}

.icon-box {
    border-left: 1px solid #2462ab;
    text-align: center;
    box-sizing: border-box;
    background-position: center center;
    background-repeat: no-repeat;
    background-size:auto 65%;

}
.icon-box.icon-l {

    background-size:50% auto;

}

.slick-carousel {
    height: 40px;
}

.slick-carousel img {
    height: auto;
    width: auto;
    margin-left: auto;
    margin-right: auto;
    max-width:100%;
    max-height:100%;
}

.services-title {
    font-size: 14px;
    font-weight: 600;
    color: #FFF;
    position: absolute;
    left: -80px;
    top: 26px;
    margin-left: 18px;
}

.slider-controls-holder {
    margin-top: -15px;
    text-align: center;
    margin-left: 3px;
}

.slider-controls {
    height: 23px;
    background-color: #154e9c;
    border-radius: 3px;
    text-align: center;
    font-size: 11px;
    color: #a4d3f3;
    width: auto;
    padding: 3px 22px 0px 22px;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    position: relative;
    width: 250px;
    font-weight: 600;
    text-transform: uppercase;
}

.arrow-down {
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid #154e9c;
    margin-left: auto;
    margin-right: auto;
}

.cover-up {
    position: absolute;
    background-color: #2e6fb5;
    width: 5px;
    height: 34px;

    z-index: 11;
    left:73px;
    top:-6px;
}
.base p {
    float: right;
    padding-top: 15px;
}
.base p a {
    color:#989898;
    font-size:12px;
    text-decoration: none;
    padding-right: 20px;

}
.base p a:hover{
    color:#3c3c3c;

    text-decoration: none;


}
p.website  {
    display: block;
    clear:both;
    width:100%;
    text-align: center;

}
p.website a  {

    font-weight: 600;
    font-size: 18px;
    color:#353435;
}
p.website a:hover  {

    font-weight: 600;
    font-size: 18px;
    color:#124284;
}
.accordian-holder.active {
    padding-top: 15px;
    padding-bottom: 15px;
}
.cms-bann {
    position: relative;
    background-color: #113f7e;
    min-width: 100%;
    min-height: 70px;
    clear: both;
    width:420px;
}

.cms-bann-inner {
    padding-top: 65px;
    position: absolute;
    width: 100%;
    height: auto;
}
.cms-bann-inner img {
    width:575px;
    height: auto;
}
.directions a {
    color:#a4d3f3;
    text-decoration: none;
}
.directions a:hover {
    color:#fff;
    text-decoration: none;
}
a.back-to-map {

    padding-top: 10px;
    float: right;
    color: #fff;
    font-weight: 600;
    .fa-chevron-left {
    -moz-transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
        font-size: 12px;
        margin-right: 4px;
    }
}
a.back-to-map:hover {
    text-decoration: none;
    .fa-chevron-left {
      
        margin-right: 0px;
    }
}
/* Mobile */

@media (max-width: 767px) {
    body.landing {
        background: url(none) !important;
    }
    .navbar {
        margin-bottom: 20px;
    }
    .navbar-static-top {
        padding: 0px;
        margin: 0px;
    }
    .cont-start {
        background-color: #fff;
        height: 50px;
        padding: 0px;
    }
    .cont-search {
        background-color: #fff;
        height: 500px;
    }
    .cont-start .container {
        padding: 0px;
        margin: 0px;
    }
    .cont-slogan {
        background-color: #fff;
        height: 70px;
        margin-top: -35px;
        text-align: center;
        padding: 0px;
    }
    .cont-slogan h2 {
        font-family: arial, helvetica, sans-serif;
        font-size: 15px;
        color: #353435;
        line-height: 50px;
        margin: 0px;
        padding: 0px;
        position: absolute;
        z-index: 1111;
        width: 100%;
        left: 0px;
        right: 0px;
    }
    .navbar-brand {
        float: none;
        width: 100%;
        display: block;
        text-align: center;
        padding: 0px;
        margin: 0px;
    }
    .navbar-static-top .navbar-brand .logo {
        margin-top: 30px;
        height: auto;
        width: 270px;
        margin-left: auto;
        margin-right: auto;
        padding: 0px;
    }
    .landing-search {
        margin-top: 0px;
    }
    .form-inline .form-control.city {
        width: 100%;
        border-radius: 0px;
    }
    .form-group {
        margin-bottom: 2px;
    }
    .btn-primary {
        width: 100%;
        border-radius: 0px;
        text-align: left;
        margin-bottom: 80px;
        background-color: #e62332;
        padding-left: 21px;
    }
    .btn-default {
        width: 100%;
        border-radius: 0px;
        text-align: left;
        line-height: 30px;
        padding-left: 21px;
    }
    .glyphicon-search {
        float: right;
        margin-right: 5px;
    }
    .btn-default:hover,
    .btn-default:focus,
    .btn-default.focus,
    .btn-default:active,
    .btn-default.active,
    .open > .dropdown-toggle.btn-default {
        width: 100%;
    }
    .btn-group,
    .btn-group-vertical {
        width: 100%;
    }
    .fa-angle-down {
        margin-right: 9px;
    }
    .glyphicon-search {
        font-size: 20px;
        float: right;
        margin-left: 10px;
        margin-right: 8px;
        margin-top: -2px;
        color: #fff;
    }
    .footer {
        position: relative;
        bottom: 0;
        width: 100%;
        height: 150px;
        clear: both;
    }
    .cont-footer {
        margin: 0px;
        padding: 0px;
    }
    .footer-left {
        height: 150px;
        width: 100%;
        text-align: center;
        background-repeat: no-repeat;
    ;
        background-position: center center;
    }
    .footer-logo {
        width: 250px;
        margin-top: 30px;
        margin-left: auto;
        margin-right: auto;
        padding: 0px;
    }
    .footer-right {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 15px;
        text-align: center;
        width: 100%;
    }
    .footer-right p {
        text-align: center;
        width: 100%;
    }
    body.b-locator .form-inline .form-control.city {
        width: 100%;
    }
    .tab-content > .tab-pane {
        display: block;
    }
    .fade {
        opacity: 1;
        -webkit-transition: opacity .15s linear;
        -o-transition: opacity .15s linear;
        transition: opacity .15s linear;
    }
    .mask-area {
        width:100%;
        min-height: 740px;
        overflow: hidden;
        height: auto;


    }
    .full-holder {
        width:100%;
        position: relative;


    }
    .mob-left {
        position: absolute;
        top:0px;
        left:0px;
        width:100%;
        min-height: 100%;
        z-index: 11111;

    }
    .mob-right {
        position: absolute;
        top:0px;
        left:0px;
        width:100%;
        min-height: 100%;
        z-index: 111121;
        margin-left: 100%;
        background-color: #2f6fb6;


    }
    .b-locator .navbar-static-top {
        //display: none;
    }
    .clearing {
        display: block;
        clear: both;
        width:100%;
        height: 10px;
    }
    .b-locator .base {
        margin-top: -110px;
        margin-bottom: 0px;
        padding-bottom: 0px;
        height: auto;
    }
    .footer {
        //display: none;
    }
    .no-pads {
        padding-left: 0px;
        padding-right: 0px;
        margin-left: 0px;
    }
    .location-links a.loc,
    .location-links a.loc:focus,
    .location-links a.loc:active {
        display: block;
        min-height: 80px;
        background-image: url(../images/mob-right-arrow.png);
        background-repeat: no-repeat;
        background-position: 95% center;

        height: auto;
        padding-bottom: 60px;
    }
    .location-links a.loc.active {

        min-height: 80px;

    }
    .location-links a.loc:hover {

        background-image: url(../images/mob-right-arrow-over.png);

    }
    .slick-prev, .slick-next {
        display: none;
    }
    .slider-controls-holder {
        margin-left: 0px;
    }
    .slider-controls {
        width: auto;
    }
    .slick-track {
        margin-left: 0px;
    }
    .no-pads-second {
        padding-right: 0px;
        margin-right: -10px;
        padding-left: 0px;
    }
    .mobile-slick {
        padding-left:10px;
        padding-right: 10px;
        margin: 0px;

    }
    .number {
        margin-top: 10px;
        background-color: #113f7e;
    }
    .comp-name {
        padding-top: 7px;
        font-size: 14px;
        line-height: 16px;
        white-space: normal;
        overflow: hidden;

        margin-top: 0px;
  
        width:250px;
    }

    .distance {
        font-size: 14px;
        color: #fff;
        font-weight: 600;
        line-height: 16px;
        background-image: url(../images/link-pointer-off.png);
        background-position: 0px 9px;
        padding-left: 20px;
        background-repeat: no-repeat;

        margin-top: -3px;
        float: left;

        padding-top: 0px;

    }

    a.b-search, a.b-search:hover {
        width:100%;
        height: 60px;
        line-height: 60px;
        font-size: 14px;
        color:#fff;
        background-image: url(../images/left-arrow-white.png);
        background-repeat: no-repeat;
        background-position: 34px center;

        display: block;
        background-color: #353535;
        padding-left: 60px;
        text-decoration: none;
    }
    a.b-list, a.b-list:hover {
        width:100%;
        height: 60px;
        line-height: 60px;
        font-size: 14px;
        color:#fff;
        background-image: url(../images/left-arrow-white.png);
        background-repeat: no-repeat;
        background-position: 34px center;

        display: block;
        background-color: #154387;
        padding-left: 60px;
        text-decoration: none;
    }
    .title-holder {
        padding-top: 0px;
    }
    .main-info {
        padding: 20px;
        padding-bottom: 0px;

    }
    .address, .directions, .opening {
        border-top: 1px solid #2059a0;
        border-right: none;
        padding-top: 15px;
        padding-bottom: 4px;
        height: auto;
        background-position: 37px 15px;

    }
    .main-info div {
        height: auto;
        padding-left: 71px;

    }
    .main-info {
        margin-left: -20px;
        margin-right: -20px;

    }
    .details-title {
        position: relative;
        font-size: 16px;

        white-space: normal;
        overflow: visible;

        padding-left: 70px;
        padding-top: 32px;
        min-height: 60px;
        margin-bottom: 17px;
    }
    .details-title .number {
        position: absolute;
        top:24px;
        left:26px;
        background-color: #113f7e;
        margin: 0px;
        padding: 0px;
    }
    a.website-dealer {
   
        margin-bottom: -9px;
        font-size: 14px;
        font-weight: 600;
        color: #fff;
        padding-left: 34px;
        background-position: 35px 10px;
        background-size: 19px 19px;
        padding-bottom: 0px;
        clear: both;
        border-top: 1px solid #2059a0;
        display: block;
        width:100%;
        padding-top: 10px;
        .fa-desktop {
            margin-right: 14px;
            color: #a4d3f3;
        }
       
    } 
    a.google-link {
        background-image: url(../images/google-icon.png);
        display: block;

        background-repeat: no-repeat;
        width:100px;
        height: 100px;
        background-position: center center;
        float: right;


    }
    .main-info .mob-miles {
        border-top: 1px solid #2059a0;
        padding-left: 40px;
        height: 42px;
        padding-top: 3px;
    }
    .main-info .distance {

        font-size: 14px;
        color: #fff;
        font-weight: 600;
        line-height: 37px;
        background-image: url(../images/link-pointer-on.png);
        background-position: left 10px;
        padding-left: 0px;
        background-repeat: no-repeat;

        margin-top: 0px;
        float: none;
        clear: both;
        width:100%;
        padding-left: 32px;
        margin-left: -2px;
    }
    .mobile-slick {
        height: 70px;
    }
    .bottom-link {
        clear: both;
    }
    body.b-locator .footer {
        clear: both;
        margin-top: 00px;
        position: relative;
        width: 100%;
        height: 115px;
        background-color: #2d2d2d;
    }
    .mob-left a.loc-last{
        border-bottom: none;
    }
    .base p {
        float:none;
        text-align: center;
    }
    body.b-locator p.website  {
        margin-top: -20px;
        padding-top: -10px;
    }
    .b-locator .footer-right {

        bottom: -20px;

    }
    .cover-up {
        position: absolute;
        background-color: #2e6fb5;
        width: 5px;
        height: 34px;

        z-index: 11;
        left:70px;
        top:-8px;
    }
    a.add-email {
        display: block;
        position: absolute;
        bottom:15px;
        right:20px;
        background-image: url(../images/ad-email.png);
        background-repeat: no-repeat;
        width: 36px;
        height: 22px;
        text-indent: -30000px;
    }
    a.add-phone {
        display: block;
        position: absolute;
        bottom:14px;
        right:80px;
        background-image: url(../images/ad-phone.png);
        background-repeat: no-repeat;
        width: 24px;
        height: 25px;
        text-indent: -30000px;
    }


}
/* Tablet */

@media (min-width: 768px) and (max-width: 992px) {

    .dealer-map {
        height: 316px;
    }

    .slogan h1 {
        font-size: 18px;
    }
    .form-inline .form-control.city {
        width: 270px;
        font-size: 14px;
        padding-top: 12px;
    }
    .fa-angle-down {
        margin-top: 2px;
    }
    .btn-default {
        font-family: $font-primary;
        font-size: 14px;
        font-weight: 400;
        line-height: 33px;
        color: #989898;
        background-color: #424242;
        border: none;
        height: 44px;
        width: 210px;
        text-align: left;
    }
    .btn-default:hover,
    .btn-default:focus,
    .btn-default.focus,
    .btn-default:active,
    .btn-default.active,
    .open > .dropdown-toggle.btn-default {
        width: 210px;
    }
    .share-text {
        left: -15px;
    }
    .footer {
        position: fixed;
        margin-top: 70px;
        clear: both;
        width: 100%;
        height: auto;
    }
    body.b-locator .form-inline .form-control.city {
        width: 160px;
    }
    .f-locator {
        padding-left: 0px;
        padding-right: 0px;
    }
    .start {
        padding-right: 10px;
    }
    body.b-locator .glyphicon-search {
        margin-left: 0px;
        margin-right: 0px;
    }
    .nav-tabs > li.active > a.tab-red,
    .nav-tabs > li.active > a.tab-red:hover,
    .nav-tabs > li.active > a.tab-red:focus {
        color: #fff;
        cursor: default;
        background-color: #d5212e;
        border: none;
        border-bottom-color: transparent;
        border-left: 2px solid #e9e9e9;
    }
    .nav-tabs > li.active > a.tab-blue,
    .nav-tabs > li.active > a.tab-blue:hover,
    .nav-tabs > li.active > a.tab-blue:focus {
        color: #fff;
        cursor: default;
        background-color: #2e6fb5;
        border: none;
        border-bottom-color: transparent;
        border-left: 2px solid #e9e9e9;
    }
    .nav-tabs > li > a {
        margin-right: 0px;
        line-height: 1.42857143;
        border: none;
        border-radius: 0px;
        width: 120px;
        height: 60px;
        border-left: 2px solid #e9e9e9;
    }
    .nav-tabs > li > a.tab-red {
        padding-top: 20px;
        background-color: #d5212e;
        color: #fff;
        font-weight: 600;
        font-size: 16px;
    }
    .nav-tabs > li > a.tab-blue {
        padding-top: 20px;
        background-color: #2e6fb5;
        color: #fff;
        font-weight: 600;
        font-size: 16px;
    }
    .tab-text {
        float: left;
        margin-left: -3px;
    }
    .tab-icon {
        padding-top: 6px;
        float: right;
        margin-right: -6px;
    }
    .map-icon {
        margin-top: -15px;
        height: 26px;
    }
    .details-icon {
        margin-top: -15px;
        height: 21px;
    }
    .no-pads {
        padding-left: 10px;
    }
    .no-pads-second {
        padding-right: 0px;
        margin-right: 0px;
        padding-left: 0px;
        margin-right: 0px;
        padding-top: 20px;
    }
    .slider-controls-holder {
        margin-left: 0px;
    }
    .services-title {
        left: -70px;
    }
    .cover-up {

        top: -8px;
        left: 66px;
    }
    .address {
        background-image: url(../images/detail-pointer.png);

        background-repeat: no-repeat;
        background-position: 50px 2px;
    }
    .address p,
    .opening p,
    .directions p {
        padding-left: 30px;
    }
    .opening {
        background-image: url(../images/detail-clock.png);

        background-repeat: no-repeat;
        background-position: 47px 2px;
    }
    .directions {
        background-image: url(../images/detail-directions.png);

        background-repeat: no-repeat;
        background-position: 47px 2px;
    }
    .t-cms-bann {
        position: relative;
        background-color: transparent;
        width: 100%;
        min-height: 140px;
        clear: both;
        margin-left: -10px;
    }
    .t-cms-bann-inner {
        padding-top: 20px;
        position: absolute;
        width: 100%;
        height: auto;
    }

    .details-title {
        color: #fff;
        font-size: 18px;
        font-weight: 600;

        display: block;
        float: left;
        padding-left: 30px;
    }
    a.email-dealer {
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        background-size: auto 16px;
        margin-left: 20px;
        margin-right: 68px;
    }
    .tel-num {

        padding-right: 0px; 
        width: 250px;
        display: block;
        float: left;
        padding-left: 20px;
    }  

    .main-details-holder {

        padding-right: 0px;
    }
    .location-links a.loc {
        background-image: url(../images/accordian-down.png);
        background-repeat: no-repeat;
        background-position: 98% 25px;

    }

    .location-links a.active {
        background-image: url(../images/accordian-up.png);
        background-position: 98% 25pxs;

    }

    .map-holder-base {
        padding-top: 30px;
        height: 60px;
    }
    .map-holder-base .details-title {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        width: auto;
        display: block;
        float: left;
        padding-left: 7px;
        padding-right: 10px;


        clear:both;
        margin-right: 10px;
        border-right: 1px solid #c4131b;
        padding-top: 2px;
        line-height: 22px;

    }
    .map-holder-base .tel-num, .map-holder-base .tel-num a {
        font-size: 14px;
        font-weight: 400;
        color: #fff;
        padding-left: 25px;
        background-image: url(../images/tel-red.png);
        background-position: left center;
        background-repeat: no-repeat;

        padding-right: 10px;
        margin-right: 10px;
        border-right: 1px solid #c4131b;
        font-weight: 600;
    }

    .map-holder-base a.email-dealer {
        background-image: url(../images/email-red.png);

        padding-left: 35px;
        margin-left: 0px;
        font-weight: 600;
    }
    .map-holder-base a.email-dealer:hover, .map-holder-base a:hover {
        color:#f8abad;
        text-decoration: none;
    }

    .map-holder-base a.cta-list {
        font-size: 14px;
        font-weight: 400;
        color: #fff;
        padding-left: 25px;
        background-image: url(../images/list-red.png);
        background-position: right 0px;
        background-repeat: no-repeat;
        background-size: auto 18px;
        padding-right: 30px;


        font-weight: 600;
        float: right;
    }

    .map-holder-base a.cta-list:hover {

        color: #f8abad;

    }
    .fa-angle-down {
        margin-top: 2px;
        float: right;
        -moz-transition: all 0.5s ease;
        -webkit-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        transition: all 0.5s ease;
        -webkit-transform: none;
        -moz-transform: none;
        -o-transform: none;
        -ms-transform: none;

    }

    .btn-group.open .fa-angle-down {
        -moz-transition: none;
        -webkit-transition: none;
        -o-transition: none;
        transition: none;
        -webkit-transform: none;
        -moz-transform: none;
        -o-transform:none;
        -ms-transform: none;

    }
    .miles {
        padding-right: 30px;
    }
    .dealer-contact {
        background-color: #353435;
        padding-top: 12px;
        padding-bottom: 10px;
        padding-left: 15px;
    }
    .small-map-holder {
        padding-top: 0px;
    }
    .accordian-holder.active {
        padding-top: 4px;

    }
}
/* Small Desktop */

@media (min-width: 993px) and (max-width: 1200px) {
    .form-inline .form-control.city {
        width: 430px;
    }
    .share-text {
        float: left;
        font-size: 15px;
        color: #fff;
        position: absolute;
        top: 13px;
        left: -19px;
    }
    body.b-locator .form-inline .form-control.city {
        width: 464px;
    }
    .tab-content > .tab-pane {
        display: block;
    }
    .fade {
        opacity: 1;
        -webkit-transition: opacity .15s linear;
        -o-transition: opacity .15s linear;
        transition: opacity .15s linear;
    }
    .comp-name {

        font-size: 14px;

        white-space: nowrap;
        overflow: hidden;
    }
    .map-holder {
        background-color: #2e6fb5;
    }
    .details-title {
        font-size: 15px;

        margin-top: -2px;
    }




    .opening {
        width: 38%;
    }
    .cms-bann-inner {
        padding-top: 0px;
        margin-top: 0px;
        position: absolute;
        width: 100%;
        height: auto;
    }
    .mob-left a.loc-last {
     
    }

    .dealer-map {
        height: 200px;
    }
    .tab-pane {
        padding-top: 0px; 
        padding-bottom: 0px; 
    }
    .details-title {
        padding-top: 8px;
        line-height: 24px;
    }
.dealer-contact {
    background-color: #353435;
    padding-top: 12px;
    padding-bottom: 10px;
    padding-left: 15px;
}
.tel-num {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    width:190px;
    padding-right: 0px;
    display: block;
    float: left;
}

a.email-dealer {
    font-size: 16px;
    padding-right: 72px;
}

a.website-dealer {
    font-size: 16px;
}
a.website-dealer:hover{
    color: #b0d9f6;
    text-decoration: none;
}
.dealer-contact {
    .fa-phone {
        font-size: 20px;
    }
    .fa-envelope-o {
        font-size: 19px;
    }
    .fa-desktop {
        font-size: 17px;
    }
}
.hide-desktop {
    display: none;

}
.first-map {
    padding-top: 20px;

    padding-right: 0px;
    padding-bottom: 15px;

}
#dealers-canvas-2 {
    height: 481px;
}
.cms-bann-inner {
    padding-top: 43px;

}
}
/* Large */

@media (min-width: 1201px) {
    .tab-content > .tab-pane {
        display: block;
    }
    .fade {
        opacity: 1;
        -webkit-transition: opacity .15s linear;
        -o-transition: opacity .15s linear;
        transition: opacity .15s linear;
    }
    .map-holder {
        background-color: #2e6fb5;
    }
    .tab-pane {
        padding-top: 0px; 
        padding-bottom: 0px; 
    }
    .details-title {
        padding-top: 10px;
        line-height: 24px;
    }
.dealer-contact {
    background-color: #353435;
    padding-top: 12px;
    padding-bottom: 10px;
    padding-left: 15px;
}
.hide-desktop {
    display: none;

}
.first-map {
    padding-top: 20px;

    padding-right: 0px;
    padding-bottom: 15px;

}
#dealers-canvas-2 {
    height: 527px;
}
}


.tab-content > .tab-pane p {
    display: block;
    color: #fff;
}

.border-red {
    border: solid 1px red;
}

.share-bubble::before {
    background-color: #000;
    content: "\00a0";
    display: block;
    height: 16px;
    position: absolute;
    top: 11px;
    transform:             rotate( 29deg ) skew( -35deg );
    -moz-transform:    rotate( 29deg ) skew( -35deg );
    -ms-transform:     rotate( 29deg ) skew( -35deg );
    -o-transform:      rotate( 29deg ) skew( -35deg );
    -webkit-transform: rotate( 29deg ) skew( -35deg );
    width:  20px;
    box-shadow: none;
    left: -9px;
}

#iw-container {
    position: relative;
}

#iw-container  .iw-title {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: 600;
    padding: 15px;
    padding-bottom: 5px;
    padding-right: 50px;
    background-color: #2e6fb5;
    color: white;
    margin: 1px;
    border-radius: 2px 2px 0 0; /* In accordance with the rounding of the default infowindow corners. */
}

#iw-container .iw-title a {
    color: white;
}

.gm-style-iw {
    width: 350px !important;
    top: 30px !important;
    left: -220px !important;
    background-color: #2e6fb5;
    box-shadow: 0 1px 6px rgba(178, 178, 178, 0.6);
    border: none;
    border-radius: 0px; // apply 10px to the bottom corners of the infowindow
}
#bodyContent  {
    width: 350px;

}
#bodyContent p {

    font-family: 'Open Sans', sans-serif;
    font-size:11px;
    padding-left: 15px;
    font-weight: 400;
    width: 100%;
}
#bodyContent p a {

    font-family: 'Open Sans', sans-serif;
    font-size:11px;

    font-weight: 400;
    color:#fff;
    text-decoration: none;
    margin-right: 7px;
    border-bottom: #fff 1px solid;
}
#bodyContent p a:hover {
    text-decoration: none;
    border-bottom: none;

}
.w-access {
    margin-left: 15px;
    margin-bottom: 6px;
}
.map-number {

    height: 48px;
    width:48px;
    border-radius: 50%;
    border: 2px solid #4188c7;
    color:white;
    font-size:33px;
    line-height: 48px;
    text-align: center;
    position: absolute;
    top:15px;
    right: 15px;
}
#dealers-canvas > div > div:nth-child(1) > div:nth-child(3) > div:nth-child(4) > div > div:nth-child(1) > div:nth-child(3) > div:nth-child(1) > div {
    background-color: rgb(46, 111, 181);
    display: none;
}

#dealers-canvas > div > div:nth-child(1) > div:nth-child(3) > div:nth-child(4) > div > div:nth-child(1) > div:nth-child(3) > div:nth-child(2) > div {
    background-color: rgb(46, 111, 181);
    display: none;
}
#dealers-canvas-2 > div > div:nth-child(1) > div:nth-child(3) > div:nth-child(4) > div > div:nth-child(1) > div:nth-child(3) > div:nth-child(1) > div {
    background-color: rgb(46, 111, 181);
    display: none;
}

#dealers-canvas-2 > div > div:nth-child(1) > div:nth-child(3) > div:nth-child(4) > div > div:nth-child(1) > div:nth-child(3) > div:nth-child(2) > div {
    background-color: rgb(46, 111, 181);
    display: none;
}
.map-down {
    position: absolute;
    bottom:-10px;
    left:43%;
}